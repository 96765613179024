import React from "react";
import Footer from "../../../components/Footer/Footer";
import CompanyLeftNav from "../../../components/TwoColumnLeftNav/CompanyLeftNav";

const Iso = () => {
	const ContentJa = [
		{
			title: "対象規格",
			content: "ISO 9001:2015",
		},
		{
			title: "認定機関",
			content: "UKAS",
		},
		{
			title: "審査登録証番号",
			content: "JP00/18832",
		},
	];

	const ContentEn = [
		{
			title: "Target standard",
			content: "ISO-9001:2015",
		},
		{
			title: "Accredit Organization",
			content: "UKAS",
		},
		{
			title: "Certificate",
			content: "JP00/18832",
		},
	];

	const IsoJa = () => {
		return (
			<div className="iso_content content-container">
				<div className="iso_title">
					<h2 className={"large bold"}>宮城工場は ISO9001取得事業所です</h2>
				</div>
				<div className="iso_flex">
					<div className="iso_image">
						<img
							src="/asset/iso9001_2021.png"
							height="300"
							width="200"
							alt="iso登録証"
						/>
					</div>
					<div className="iso_right">
						<ul className="iso_ul">
							{ContentJa.map((InfoItem, index) => {
								return (
									<li className="flex" key={index}>
										<p className="medium">{InfoItem.title}</p>
										<p className="medium">{InfoItem.content}</p>
									</li>
								);
							})}
							<li className="flex">
								<p className="medium">証書有効期間</p>
								<p className="medium">
									2021年6月24日より<br className="sp"></br>2024年6月24日
								</p>
							</li>
						</ul>
					</div>
				</div>
			</div>
		);
	};

	const IsoEn = () => {
		return (
			<div className="iso_content content-container">
				<div className="iso_title">
					<h2 className={"large bold"}>
						Our Miyagi Factory is ISO9001 certified.
					</h2>
				</div>
				<div className="iso_flex">
					<div className="iso_image">
						<img
							src="/asset/iso9001_2021.png"
							height="300"
							width="200"
							alt="iso certificate"
						/>
					</div>
					<div className="iso_right">
						<ul className="iso_ul">
							{ContentEn.map((InfoItem, index) => {
								return (
									<li className="flex" key={index}>
										<p className="medium">{InfoItem.title}</p>
										<p className="medium">{InfoItem.content}</p>
									</li>
								);
							})}
							<li className="flex">
								<p className="medium">Validity</p>
								<p className="medium">
									from 24 June 2021 <br className="sp"></br>until 24 June 2024
								</p>
							</li>
						</ul>
					</div>
				</div>
			</div>
		);
	};

	const pathName =
		typeof window !== "undefined" ? window.location.pathname : "";

	return (
		<>
			<article className="company-info two-column-left-nav">
				<CompanyLeftNav />
				<section className="main-content">
					{pathName.indexOf("/en") !== -1 ? <IsoEn /> : <IsoJa />}
				</section>
			</article>
			<Footer />
		</>
	);
};

export default Iso;
